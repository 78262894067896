<!--
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-18 12:59:13
 * @FilePath: \groundle_mobile\src\views\Home.vue
-->
 
<template>
	<div class="home page">
		>
		<vMenu @beforeLeave="clearPage"></vMenu>

		<swiper
			@slide-change-transition-start="onSwiperSlideChangeTransitionStart"
			@click="gotoMap"
			ref="homeSwiper"
			:options="swiperOptions"
		>
			<swiper-slide>
				<div class="swiper-slide">
					<div class="home-p1 swiper-inner">
						<img src="../assets/logo_home.png" class="logo" />
					</div>
				</div>
			</swiper-slide>
			<swiper-slide>
				<div class="swiper-slide">
					<div class="home-p2 swiper-inner">
						<img src="../assets/logo_home.png" class="logo" />
					</div></div
			></swiper-slide>
			<swiper-slide>
				<div class="swiper-slide">
					<div class="home-p3 swiper-inner">
						<img src="../assets/logo_home.png" class="logo" />
					</div></div
			></swiper-slide>
		</swiper>

		<div class="sound-holder holder">
			<div class="txt" style="text-align: right">TURN ON THE SOUND</div>
			<img src="../assets/icon_vol.png" class="home-icon" />
			<div class="txt" style="text-align: left">FOR A BETTER EXPERIENCE</div>
		</div>

		<div class="enter-holder holder">
			<img src="../assets/btn_enter.png" @click="gotoMap" class="home-btn" />
		</div>
	</div>
</template>
<style scoped>
	.txt {
		width: 42%;
		display: inline-block;
		/* background: yellow; */
	}
	.home-p1 {
		background-image: url("../assets/home_p1.jpg");
	}

	.home-p2 {
		background-image: url("../assets/home_p2.jpg");
	}

	.home-p3 {
		background-image: url("../assets/home_p3.jpg");
	}

	.swiper-container {
		width: 100%;
		height: 100%;
	}
	.swiper-inner {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-size: cover;
		background-position: center;
	}

	.home-p1,
	.home-p2,
	.home-p3,
	.home-p4,
	.home-p5 {
		background-size: cover;
		background-position: center;

		display: flex;
		justify-items: center;
		align-items: center;
	}
	.logo {
		width: 300px;
		margin: auto;
	}
	.sound-holder {
		bottom: 117px;
		z-index: 10;

		color: white;

		font-size: 21px;
	}
	.enter-holder {
		bottom: 217px;
		z-index: 10;
	}
	.enter-holder img {
		width: 91px;
	}
	.sound-holder * {
		display: inline-block;
	}
	.page {
		overflow: hidden;
		touch-action: none;
	}
	.home-icon {
		width: 48px;
		max-width: 9%;
		padding: 0 28px 0 28px;
		vertical-align: middle;
	}
</style>
<script>
	import vMenu from "../components/menu.vue";

	export default {
		name: "Home",
		data() {
			return {
				swiperOptions: {
					loop: false,
					speed: 2000,
					autoplay: {
						delay: 2000, //3秒切换一次
					},
					effect: "fade",
					fadeEffect: {
						crossFade: true,
					},
					on: {
						slideChange: function (e) {},
					},
				},
			};
		},
		components: { vMenu },
		computed: {
			swiper() {
				return this.$refs.homeSwiper.$swiper;
			},
		},
		methods: {
			swipeHandler(direction) {
				console.log(direction); // May be left / right / top / bottom
				if (direction == "top") {
					// do next page
					this.$router.replace("/Map");
				}
			},
			clearPage(val, callback) {
				callback("ok");
			},
			// handleScroll() {
			// 	console.log("SCROLL");

			// 	this.$router.push("/Map");
			// },

			gotoMap() {
				this.clearPage({}, () => {
					this.$router.replace("/Map");
				});
			},

			goto(name) {
				this.$router.push(name);
			},
			onSwiperSlideChangeTransitionStart() {
				// if (this.swiper.activeIndex == 0) {
				// 	this.$router.replace("/Map");
				// }
			},
		},

		mounted() {
			this.$ba.trackPageview(this.$route.path);
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
		},
	};
</script>
