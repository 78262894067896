<!--
 * @Date: 2021-07-15 13:50:08
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-16 19:12:58
 * @FilePath: \groundle_mobile\src\components\menu.vue
--> 
<template>
	<div class="menu" :class="classObject">
		<transition name="slideup">
			<div class="header">
				<img srcset="../assets/logo_map.png" @click="goto('/')" class="logo" />
				<img
					src="../assets/icon_menu.png"
					class="btn-menu"
					@click="showMenu = !showMenu"
				/>
			</div>
		</transition>
		<transition name="slide">
			<div class="float-menu" v-show="showMenu">
				<img
					src="../assets/icon_back.png"
					@click="showMenu = !showMenu"
					class="back"
				/>
				<br />
				<br /><br /><br /><br />
				<!-- <div class="menu-txt" @click="goto('/')">HOME</div> -->
				<!-- <div class="menu-txt" @click="goto('/Map')">MAP</div> -->

				<div class="menu-txt SourceHanSansRegular" @click="goto('/Scent')">
					SCENT<span class="txt-small"> 香气</span>
				</div>
				<div class="menu-txt SourceHanSansRegular" @click="goto('/Map')">
					MAP<span class="txt-small"> 地图</span>
				</div>
				<div class="menu-txt SourceHanSansRegular" @click="goto('/About')">
					BRAND STORY<span class="txt-small"> 品牌故事</span>
				</div>
				<div class="menu-txt SourceHanSansRegular" @click="goto('/Contact')">
					CONTACT<span class="txt-small"> 联系我们</span>
				</div>
				<div
					class="menu-txt SourceHanSansRegular"
					@click="linkTo('https://www.coslets.com/')"
				>
					ORDER<span class="txt-small"> 企业订单</span>
				</div>
				<!-- <div
					class="menu-txt SourceHanSansRegular"
					@click="linkTo('https://mp.weixin.qq.com/s/rAlOrcLmTMr67ZB2DzcLDQ')"
				>
					ORDER<span class="txt-small"> 企业订单</span>
				</div> -->
			</div>
		</transition>

		<transition name="fade">
			<div class="float-menu-all" v-show="showMenu"></div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: "vMenu",
		props: {},
		data() {
			return {
				showMenu: false,
			};
		},
		methods: {
			goto(name) {
				console.log(name);
				console.log(this.$route.name);
				if (this.$route.name == `/${name}`) {
					//
				} else {
					// this.$emit("beforeLeave", {});
					// this.showMenu = false;
					// this.$router.replace(name);

					this.$emit("beforeLeave", {}, (val) => {
						console.log(val);
						this.showMenu = false;
						this.$router.push(name);
					});
				}
			},
			linkTo(path) {
				window.location.href = path;
			},
		},

		computed: {
			classObject() {
				switch (this.$route.name) {
					case "Map":
						return "txt-map";
					case "Scent":
						return "txt-map";
					default:
						return "";
				}
			},
		},
	};
</script>
<style scoped>
	.txt-small {
		font-size: 27px;
	}
	.txt-map {
		color: #493c33 !important;
	}
	.txt-map .map-only {
		display: inline-block !important;
	}
	.map-only {
		display: none !important;
	}
	.left-menu {
		float: left;
		margin-left: 30px;
	}
	.right-menu {
		float: right;
		margin-right: 30px;
	}
	.menu {
		position: absolute;
		top: 0;
		z-index: 10;
		color: white;
		font-size: 30px;
		/* margin-top: 80px; */
		width: 100%;
	}
	.menu-txt {
		padding: 18px 0 18px 0;
		/* line-height: 45px; */

		cursor: pointer;
	}
	.float-menu {
		width: 70vw;
		height: 100%;
		position: fixed;
		right: 0;
		background: white;
		top: 0;
		color: #4d3524;
		padding: 0 43px;
		text-align: left;
		z-index: 1000;
	}
	.float-menu-all {
		width: 100vw;
		height: 100%;
		position: fixed;
		right: 0;
		top: 0;
		color: #4d3524;
		text-align: left;
		z-index: 999;
		background: #242c35b5;
	}
	.back {
		position: absolute;
		right: 30px;
		top: 45px;
		height: 35px;
	}

	.header {
		width: 100%;
		height: 116px;
		background: white;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		display: flex;
	}
	.logo {
		margin: auto;
		vertical-align: middle;
		width: 175px;
	}
	.btn-menu {
		position: absolute;
		right: 30px;
		top: 44px;
		width: 32px;
	}

	.slideup-enter-active,
	.slideup-leave-active {
		transition: all 0.3s;
	}
	.slideup-enter, .slideup-leave-to /* .fade-leave-active below version 2.1.8 */ {
		transform: translateY(-4rem);
		opacity: 0;
	}

	.slide-enter-active,
	.slide-leave-active {
		transition: all 0.5s;
	}
	.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
		transform: translateX(80vw);
		opacity: 0;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>