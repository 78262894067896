/*
 * @Date: 2021-08-25 18:45:22
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-24 15:44:46
 * @FilePath: \groundle_mobile\src\main.js
 */
/*
 * @Date: 2021-07-15 12:47:28
 * @LastEditors: Woz
 * @LastEditTime: 2021-09-07 16:26:17
 * @FilePath: \groundle_mobile\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import wxjssdkPlugin from 'vue-wx-jssdk';
import axios from 'axios';


import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, {})

import VConsole from 'vconsole'

if (process.env.NODE_ENV  != 'production') {
  new VConsole()
}
// import Vue2TouchEvents from 'vue2-touch-events'

// Vue.use(Vue2TouchEvents)

import 'lib-flexible'

import ba from 'vue-ba'
Vue.use(ba, {
  siteId: "5112fb1a308e4fa8492e770ba0ac91ad"
});

// const sp = "http://120.78.80.15:6161/getPageJSAPISign"
const sp = "http://114.55.0.144:6161/getPageJSAPISign"
const options = {

  /*
  *    config可以有两种形式：object或者promise
  *    1、如果是object，则必须包含以下属性：
  *        appId: '', // 必填，公众号的唯一标识
            timestamp: , // 必填，生成签名的时间戳
            nonceStr: '', // 必填，生成签名的随机串
            signature: '',// 必填，签名
  *   
  *    2、如果是promise，则该promise的返回值中必须要能获取到上面格式的object。
  *    下面是一个promise格式的例子，该示例中，从后端的auth/base接口中获取微信配置信息。
  *    该接口返回一个对象，如：
  *    {
  *       data:{
  *           wx:{
  *               appId: 'xxx', 
                  timestamp: xx, 
                  nonceStr: 'xxx', 
                  signature: 'xxx',
  *           }
  *       }
  *    }
  *       
  *    
  * */

  config: axios({
    method: 'post',
    url: sp,
    data: {
      urlToSign: window.location.href.split('#')[0]
    }
  }),

  // 如果config是promise，path需要指定如何从promise返回值中拿到符合格式的object;
  // 如果config是一个object，则无需指定path值
  path: '',

  // 声明用到的微信api
  actions: ['onMenuShareAppMessage', 'onMenuShareTimeline']
};

Vue.use(wxjssdkPlugin, options);



Vue.config.productionTip = false
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'
var vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


vm.wx.ready(function () {
  // 分享给朋友
  vm.wx.onMenuShareAppMessage({
    title: 'groundle',
    desc: '浪漫情怀的自然主义香氛品牌',
    link: window.location.href.split('#')[0],
    imgUrl: window.location.href.split('#')[0] + '/static/share.jpg',
    success: function () {
      // alert("分享成功");
    }
  });
  // 分享到朋友圈
  vm.wx.onMenuShareTimeline({
    title: 'groundle 浪漫情怀的自然主义香氛品牌',
    link: window.location.href.split('#')[0],
    imgUrl: window.location.href.split('#')[0] + '/static/share.jpg',
    success: function () {
      // alert("分享成功");
    }
  });
});